import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import App from "app";
import { Fallback, RADFallback } from "routes/fallback";
import Fingerprint from "routes/fingerprint";
import DocAttributionIOS from "routes/doc/attribution/ios";
import DocAttributionAndroid from "routes/doc/attribution/android";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Fallback />
      </Route>
      <Route exact path="/fallback">
        <Fallback />
      </Route>
      <Route exact path="/fingerprint">
        <Fingerprint />
      </Route>
      <Route exact path="/doc/attribution/ios">
        <DocAttributionIOS />
      </Route>
      <Route exact path="/doc/attribution/android">
        <DocAttributionAndroid />
      </Route>
      <Route exact path="/dl/:id">
        <RADFallback />
      </Route>
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);
