import React, { useState, useEffect } from "react";
import Fingerprint2 from "fingerprintjs2";

export default () => {
  const [attrData, setAttrData] = useState({});

  function fingerDevice() {
    setTimeout(function () {
      Fingerprint2.get(async function (components: Array<object>) {
        const finger = components.reduce((acc: any, cur: any) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {});

        const ipDataPromise = await fetch(
          "https://www.cloudflare.com/cdn-cgi/trace"
        );
        const ipData = await ipDataPromise.text();
        const ip = ipData.substring(
          ipData.lastIndexOf("ip=") + 3,
          ipData.lastIndexOf("ts=") - 1
        );

        finger.ip = ip;

        console.log(JSON.stringify(finger));

        if ((window as any).webkit) {
          (window as any).webkit.messageHandlers.finger.postMessage(
            JSON.stringify(finger)
          );
        } else if ((window as any).finger) {
          (window as any).finger.postMessage(JSON.stringify(finger));
        }

        setAttrData(finger);
      });
    }, 500);
  }

  useEffect(() => {
    fingerDevice();
  }, []);

  return <pre>{`${JSON.stringify(attrData, null, 2)}`}</pre>;
};
